<script>
import { getUser, modUser, modUserPassword } from '@/api/admin/user'
import { signUp } from '@/api/auth'
import { getNotRegisteredLabs } from '@/api/v2lab'

export default {
  name: 'userCreate',
  components: {},
  data() {
    return {
      userId: '',
      user: {},
      notRegisteredLabs: [],
      labNoForRegister: 0
    }
  },
  async mounted() {
    this.userId = this.$route.params.userId

    this.notRegisteredLabs = await getNotRegisteredLabs()

    if (this.userId) {
      const user = await this.loadUserData(this.userId)
      this.user = user
    }
  },
  methods: {
    mapData(data) {
      return {
        ...data,
        // lastSignedAt: data.lastSignedAt ? this.$getDate(data.lastSignedAt) : '',
        updatedAt: this.$getDate(data.updatedAt),
        createdAt: this.$getDate(data.createdAt)
      }
    },

    selectLabForRegister(e) {
      this.labNoForRegister = e.target.value
    },

    goList() {
      this.$router.replace('/admin/users')
    },

    async loadUserData(id) {
      const result = await getUser(id)

      if (result.code === 'DB_DATA_NOT_FOUND') {
        alert('존재하지않는 사용자입니다.')
        this.goList()
        return null
      }

      return this.mapData(result)
    },

    async modUser() {
      return await modUser(this.user.userId, this.user)
    },

    async changeUserGroup(groupIdForChange) {
      if (!this.labNoForRegister) return alert('선택한 연구실이 없습니다')
      if (!confirm('정말로 변경하시겠습니까?')) return

      const result = await modUser(this.user.userId, { groupIdForChange, labNo: this.labNoForRegister })

      if (result.code) return alert(result.message)

      alert('변경되었습니다')
      return this.$router.go()
    },

    async createUser() {
      this.user.userId = this.userId
      this.user.termsAgreement = 1
      this.user.privacyAgreement = 1
      this.user.advertisementAgreement = 1

      return await signUp(this.user)
    },

    async resetPassword() {
      if (!confirm('비밀번호를 초기화 하시겠습니까?')) return

      const newPassword = this.$getDate(new Date())
        .split('-')
        .reduce((acc, cur) => acc + cur)

      const result = await modUserPassword(this.user.userId, newPassword)

      if (result.code) return alert(result.message)
      alert(`비밀번호가 ${newPassword}로 초기화 되었습니다.`)
    },

    async saveUser() {
      const result = this.$route.params.userId ? await this.modUser() : await this.createUser()

      if (result.code === 'ER_DUP_ENTRY' && result.message.includes('PRIMARY')) return alert('중복된 아이디 입니다.')
      if (result.code === 'ER_DUP_ENTRY') return alert('중복된 이메일 입니다.')
      if (result.code) return alert(result.message)

      alert('저장되었습니다.')
      this.goList()
    }
  }
}
</script>

<template>
  <div id="content-wrapper" class="d-flex flex-column">
    <div id="content">
      <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <h4>기본환경설정</h4>
      </nav>
      <div class="adminTable">
        <form action="#" method="post" id="basicform">
          <table>
            <colgroup>
              <col width="10%" />
              <col width="90%" />
            </colgroup>

            <tbody>
              <tr>
                <th>아이디</th>
                <td>
                  <input type="text" name="userId" v-model="userId" placeholder="아이디" :disabled="user.userId ? true : false" />
                </td>
              </tr>
              <tr v-if="user.userId">
                <th>비밀번호 초기화</th>
                <td>
                  <button class="e-btn wf__btn btn-danger" type="button" @click="resetPassword">초기화</button>
                </td>
              </tr>
              <tr v-if="!user.userId">
                <th>비밀번호</th>
                <td>
                  <input type="password" v-model="user.password" />
                </td>
              </tr>
              <tr>
                <th>그룹</th>
                <td>
                  <input type="text" name="groupName" v-model="user.groupName" placeholder="그룹" disabled />
                  <select v-if="user.groupName === 'user'" @change="selectLabForRegister">
                    <option value="">선택</option>
                    <option v-for="lab in this.notRegisteredLabs" :key="lab.labNo" :value="lab.labNo">
                      {{ lab.labName }}
                    </option>
                  </select>
                  <!-- TODO 간략한 설명 추가하기 -->
                  <p>관리자 계정이 부여되지 않은 연구실 목록 중에서 연구실을 선택해주십시오.</p>
                  <button v-if="user.groupName === 'user'" type="button" class="e-btn wf__btn btn-primary ml-2" @click="changeUserGroup(1)">
                    연구실로 변경
                  </button>
                </td>
              </tr>
              <tr>
                <th>연구실이름</th>
                <td><input type="text" name="labName" v-model="user.labName" placeholder="연구실이름" disabled /></td>
              </tr>
              <tr>
                <th>이름</th>
                <td><input type="text" name="name" v-model="user.name" placeholder="이름" /></td>
              </tr>
              <tr>
                <th>이메일</th>
                <td><input type="email" name="email" v-model="user.email" placeholder="이메일" /></td>
              </tr>
              <!-- <tr>
                <th>최근접속</th>
                <td><input type="text" name="lastSignedAt" v-model="user.lastSignedAt" disabled /></td>
              </tr> -->
              <tr>
                <th>수정일</th>
                <td><input type="text" name="updatedAt" v-model="user.updatedAt" disabled /></td>
              </tr>
              <tr>
                <th>생성일</th>
                <td><input type="text" name="createdAt" v-model="user.createdAt" disabled /></td>
              </tr>
            </tbody>
          </table>
          <button class="e-btn wf__btn btn-primary mt-2" type="button" id="save" @click="saveUser">저장하기</button>
        </form>
      </div>
    </div>
  </div>
</template>

<style>
.adminTable input {
  width: 30em;
  max-width: 30em;
}
</style>
