import axios from 'axios'
import { getToken } from '@/api/axiosUtil.js'

const instance = axios.create({
  baseURL: '/v2/lab',
  ...getToken()
})

export const getNotRegisteredLabs = async () => {
  try {
    const result = await instance.get('not-registered')
    return result.data
  } catch (error) {
    return []
  }
}
