import { useAxios } from '../axiosUtil'

const baseUrl = '/v1/user'

const userApi = async (method, opt, id) => {
  return await useAxios(`${id ? `${baseUrl}/${id}` : baseUrl}`, { ...opt, method })
}

const getUserApi = async (arg) => {
  const params = typeof arg === 'object' ? arg : {}
  const id = typeof arg !== 'object' ? arg : null

  return await userApi('GET', { params }, id)
}

const deleteUserApi = async (id) => {
  return await userApi('DELETE', {}, id)
}

const modDataModel = {
  name: '',
  email: '',
  groupId: 0
}
const putUserApi = async (id, data) => {
  const body = {}
  for (const key in modDataModel) {
    const value = data[key]
    if (value) body[key] = value
  }

  return await userApi('PUT', { data }, id)
}

export const getUser = async (idOrSearchOption) => {
  return await getUserApi(idOrSearchOption)
}
export const delUser = async (id) => {
  return await deleteUserApi(id)
}
export const modUser = async (id, data) => {
  return await putUserApi(id, data)
}
export const modUserPassword = async (id, password) => {
  return await putUserApi(`${id}/password`, { password })
}
